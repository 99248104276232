import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { ArrowRight } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { advertisements } from '../data/advert';

// Helper function to determine if a file is a video
const isVideoFile = (url: string) => {
  return url.toLowerCase().endsWith('.webm');
};

export const AdvertisementBanner = () => {
  const sortedAds = [...advertisements].sort((a, b) => a.id - b.id);
  const getRandomIndex = () => Math.floor(Math.random() * advertisements.length);

  const [currentAdIndex, setCurrentAdIndex] = useState(getRandomIndex());
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFirstCycle, setIsFirstCycle] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentAdIndex((prev) => {
          if (isFirstCycle) {
            const currentId = advertisements[prev].id;
            const nextId = currentId % sortedAds.length + 1;
            const nextIndex = advertisements.findIndex(ad => ad.id === nextId);
            if (nextIndex === 0) {
              setIsFirstCycle(false);
            }
            return nextIndex;
          } else {
            return (prev + 1) % advertisements.length;
          }
        });
        setIsTransitioning(false);
      }, 500);
    }, 6000);

    return () => clearInterval(timer);
  }, [isFirstCycle]);

  const currentAd = advertisements[currentAdIndex];

  const renderMediaContent = () => {
    if (!currentAd.showVideo || !currentAd.videoUrl) return null;

    if (isVideoFile(currentAd.videoUrl)) {
      return (
        <video
          key={currentAd.videoUrl}
          autoPlay
          loop
          muted
          playsInline
          className="h-full w-full object-cover"
        >
          <source src={currentAd.videoUrl} type="video/webm" />
        </video>
      );
    } else {
      return (
        <img
          src={currentAd.videoUrl}
          alt={`${currentAd.title} media`}
          className="h-full w-full object-cover"
        />
      );
    }
  };

  return (
    <div className="w-full h-28 sm:h-24 mx-auto relative overflow-hidden rounded-lg">
      <div
        className={`absolute inset-0 bg-gradient-to-r ${currentAd.gradient} transition-opacity duration-500 ease-in-out
          ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}
      >
        {currentAd.showVideo && currentAd.videoUrl && (
          <div className="absolute right-0 top-1/2 -translate-y-1/2 h-28 w-32 z-10 rounded-lg overflow-hidden">
            {renderMediaContent()}
          </div>
        )}

        {currentAd.showLogo && currentAd.logo && (
          <div className="absolute top-0 left-4">
            <picture>
              {currentAd.logo.type === 'webp' && (
                <source srcSet={currentAd.logo.url} type="image/webp" />
              )}
              <img
                src={currentAd.logo.url}
                alt={currentAd.logo.alt || "Logo"}
                className="h-16 w-auto"
              />
            </picture>
          </div>
        )}

        <div className="block sm:hidden h-full px-3">
          <div className="relative h-full pt-2">
            <div className="text-center max-w-[80%] mx-auto">
              <h3 className="text-base font-bold text-white">{currentAd.title}</h3>
              <p className="text-xs text-white/90 mt-0.5 whitespace-pre-line line-clamp-2">
                {currentAd.description}
              </p>
            </div>

            <Button
              variant="secondary"
              size="sm"
              className="absolute right-2 top-1/2 -translate-y-1/2 h-8 whitespace-nowrap backdrop-blur-sm bg-white/10 hover:bg-white/20 text-white border border-white/20 z-20 font-bold text-lg transition-all duration-200"
              onClick={() => window.open(currentAd.ctaUrl, '_blank')}
            >
              {currentAd.ctaText}
              <ArrowRight className="ml-1.5 h-3.5 w-3.5" />
            </Button>
          </div>
        </div>

        <div className="hidden sm:block h-full">
          <div className="relative h-full px-4">
            <div className="text-center max-w-[60%] mx-auto pt-2">
              <h3 className="text-lg font-bold text-white">{currentAd.title}</h3>
              <p className="text-sm text-white/90 mt-0.5 whitespace-pre-line">
                {currentAd.description}
              </p>
            </div>

            <Button
              variant="secondary"
              size="sm"
              className="absolute right-2 top-1/2 -translate-y-1/2 backdrop-blur-sm bg-white/10 hover:bg-white/20 text-white border border-white/20 z-20 font-bold text-lg transition-all duration-200"
              onClick={() => window.open(currentAd.ctaUrl, '_blank')}
            >
              {currentAd.ctaText}
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </div>

        {currentAd.showSocials && currentAd.socials && (
          <div className="absolute bottom-1.5 left-4 flex space-x-3">
            {currentAd.socials.telegram && (
              <a
                href={currentAd.socials.telegram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-white/80 transition-colors"
              >
                <FontAwesomeIcon icon={faTelegram} className="w-5 h-5" />
              </a>
            )}
            {currentAd.socials.twitter && (
              <a
                href={currentAd.socials.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-white/80 transition-colors"
              >
                <FontAwesomeIcon icon={faSquareXTwitter} className="w-5 h-5" />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};