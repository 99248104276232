import React, { useEffect, useRef, useState, useCallback, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Button } from "./components/ui/button";
import { Layout } from './components/layout/Layout';
import { HomePage } from './pages/Home';
import { ComingSoon } from './pages/ComingSoon';
import { ChartsPage } from './pages/Charts';
import { Loader2 } from "lucide-react";
import { TokenData, WalletState } from './types';

const DEX = lazy(() => import('./pages/DEX').then(module => ({ default: module.DEX })));
const WalletProfiler = lazy(() => import('./pages/WalletProfiler').then(module => ({ default: module.WalletProfiler })));
const PortfolioPage = lazy(() => import('./pages/Portfolio').then(module => ({ default: module.PortfolioPage })));
const MarketplacePage = lazy(() => import('./pages/Marketplace').then(module => ({ default: module.MarketplacePage })));
const Holders = lazy(() => import('./pages/Holders').then(module => ({ default: module.Holders })));
const BubbleMap = lazy(() => import('./pages/BubbleMap').then(module => ({ default: module.BubbleMap })));

const DEFAULT_TOKEN_DATA: TokenData = {
  ticker: '',
  iconUrl: undefined,
  price: {
    floorPrice: 0,
    marketCapInUsd: 0,
    change24h: 0,
    priceInUsd: 0
  },
  tradeVolume: {
    amountInUsd: 0
  },
  priceHistory: [],
  holderTotal: 0,
  transferTotal: 0,
  mintTotal: 0,
  socialLinks: [],
  marketsData: []
};

const LoadingFallback = () => (
  <div className="flex flex-col items-center justify-center min-h-[50vh]">
    <Loader2 className="h-8 w-8 animate-spin mb-4" />
    <h2 className="text-2xl font-bold">Loading...</h2>
  </div>
);

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [walletState, setWalletState] = useState<WalletState>({
    connected: false,
    address: '',
    balance: {
      kas: 0,
      tokens: [],
      confirmed: 0,
      unconfirmed: 0,
      total: 0
    },
    network: ''
  });

  const [loading, setLoading] = useState(true);
  const [tokenData, setTokenData] = useState<TokenData | null>(null);
  const [searchedToken, setSearchedToken] = useState<string>(() => {
    return localStorage.getItem('lastSearchedToken') || '';
  });
  const [searchedAddress, setSearchedAddress] = useState<string>(() => {
    return localStorage.getItem('lastSearchedAddress') || '';
  });
  const kaswareRef = useRef<any>(null);

  const getCurrentPage = () => {
    const path = location.pathname.slice(1) || 'home';
    return path;
  };

  const setCurrentPage = (page: string) => {
    navigate(`/${page}`);
  };

  useEffect(() => {
    if (searchedToken) {
      localStorage.setItem('lastSearchedToken', searchedToken);
    }
  }, [searchedToken]);

  const handleSetSearchedToken = (token: string) => {
    setSearchedToken(token);
    localStorage.setItem('lastSearchedToken', token);
  };

  const handleAccountsChanged = useCallback(async (_accounts: string[]) => {
    if (_accounts.length > 0) {
      const address = _accounts[0];
      setWalletState(prev => ({
        ...prev,
        connected: true,
        address
      }));
    } else {
      setWalletState({
        connected: false,
        address: '',
        balance: {
          kas: 0,
          tokens: [],
          confirmed: 0,
          unconfirmed: 0,
          total: 0
        },
        network: walletState.network
      });
    }
    setLoading(false);
  }, [walletState.network]);

  useEffect(() => {
    let mounted = true;

    async function init() {
      setLoading(false);
      const kasware = (window as any).kasware;
      if (kasware && mounted) {
        kaswareRef.current = kasware;
        const accounts = await kasware.getAccounts();
        if (accounts.length > 0) {
          await handleAccountsChanged(accounts);
        }
        kasware.on('accountsChanged', handleAccountsChanged);
      }
    }

    init();

    return () => {
      mounted = false;
    };
  }, [handleAccountsChanged]);

  const handleConnect = async () => {
    setLoading(true);
    try {
      const kasware = (window as any).kasware;
      if (!kasware) {
        window.location.href = process.env.NEXT_PUBLIC_KASWARE_URL || 'https://kasware.xyz';
        return;
      }
      kaswareRef.current = kasware;
      const result = await kasware.requestAccounts();
      await handleAccountsChanged(result);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      if (kaswareRef.current) {
        await kaswareRef.current.disconnect(window.location.origin);
        await handleAccountsChanged([]);
      }
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
      setLoading(false);
    }
  };

  return (
    <Layout
      connected={walletState.connected}
      address={walletState.address}
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
      currentPage={getCurrentPage()}
      setCurrentPage={setCurrentPage}
      setSearchedToken={handleSetSearchedToken}
      setSearchedAddress={setSearchedAddress}
    >
      {loading ? (
        <LoadingFallback />
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={
            <HomePage
              setCurrentPage={setCurrentPage}
              setSearchedToken={handleSetSearchedToken}
            />
          } />
          <Route path="/charts" element={
            <ChartsPage
              tokenData={tokenData || DEFAULT_TOKEN_DATA}
              searchedToken={searchedToken}
              setCurrentPage={setCurrentPage}
              setSearchedToken={handleSetSearchedToken}
            />
          } />
          <Route path="/portfolio" element={
            !walletState.connected ? (
              <div className="flex flex-col items-center justify-center min-h-[50vh]">
                <h2 className="text-2xl font-bold mb-4">Connect Wallet</h2>
                <p className="text-muted-foreground mb-4">
                  Please connect your wallet to view your portfolio
                </p>
                <Button onClick={handleConnect}>Connect Wallet</Button>
              </div>
            ) : (
              <Suspense fallback={<LoadingFallback />}>
                <PortfolioPage address={walletState.address} />
              </Suspense>
            )
          } />
          <Route path="/marketplace" element={
            <Suspense fallback={<LoadingFallback />}>
              <MarketplacePage
                connected={walletState.connected}
                userAddress={walletState.address}
                handleConnect={handleConnect} tokenData={tokenData || DEFAULT_TOKEN_DATA} />
            </Suspense>
          } />
          <Route path="/holders" element={
            <Suspense fallback={<LoadingFallback />}>
              <Holders
                searchedToken={searchedToken}
                setCurrentPage={setCurrentPage}
                setSearchedToken={handleSetSearchedToken}
                setSearchedAddress={setSearchedAddress}
              />
            </Suspense>
          } />
          <Route path="/profiler" element={
            <Suspense fallback={<LoadingFallback />}>
              <WalletProfiler searchedAddress={searchedAddress} />
            </Suspense>
          } />
          <Route path="/dex" element={
            <Suspense fallback={<LoadingFallback />}>
              <DEX />
            </Suspense>
          } />
          <Route path="/bubblemaps" element={
            <Suspense fallback={<LoadingFallback />}>
              <BubbleMap
                searchedAddress={searchedAddress}
                setSearchedAddress={setSearchedAddress}
              />
            </Suspense>
          } />
          <Route path="/launchpad" element={<ComingSoon feature="Launchpad" />} />
          <Route path="/tax" element={<ComingSoon feature="Tax Tool" />} />
          <Route path="/nfts" element={<ComingSoon feature="NFTs" />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      )}
    </Layout>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;