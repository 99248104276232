// src/components/NetworkStats.tsx
import React, { useEffect, useState } from 'react';
import { Coins, Database, Cpu } from 'lucide-react';

interface NetworkDataType {
  price: number;
  marketCap: number;
  hashrate: number;
}

const NetworkStats = () => {
  const [networkData, setNetworkData] = useState<NetworkDataType>({
    price: 0,
    marketCap: 0,
    hashrate: 0
  });

  useEffect(() => {
    const fetchNetworkData = async () => {
      try {
        const [priceRes, marketCapRes, hashrateRes] = await Promise.all([
          fetch('https://api.kaspa.org/info/price?stringOnly=false'),
          fetch('https://api.kaspa.org/info/marketcap?stringOnly=false'),
          fetch('https://api.kaspa.org/info/hashrate?stringOnly=false')
        ]);

        const [price, marketCap, hashrate] = await Promise.all([
          priceRes.json(),
          marketCapRes.json(),
          hashrateRes.json()
        ]);

        setNetworkData({
          price: price.price || 0,
          marketCap: marketCap.marketcap || 0,
          hashrate: hashrate.hashrate || 0
        });
      } catch (error) {
        console.error('Failed to fetch network data:', error);
      }
    };

    fetchNetworkData();
    const interval = setInterval(fetchNetworkData, 30000);
    return () => clearInterval(interval);
  }, []);

  const formatNumber = (num: number, decimals = 2) => {
    if (num >= 1e9) return `$${(num / 1e9).toFixed(decimals)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(decimals)}M`;
    if (num >= 1e3) return `$${(num / 1e3).toFixed(decimals)}K`;
    return `$${num.toFixed(decimals)}`;
  };

  const formatHashrate = (hr: number) => {
    if (hr >= 1e6) return `${(hr / 1e6).toFixed(2)}EH/s`;
    if (hr >= 1e3) return `${(hr / 1e3).toFixed(2)}TH/s`;
    return `${hr.toFixed(2)}GH/s`;
  };

  return (
    <div className="flex items-center space-x-6 text-sm">
      <div className="flex items-center space-x-2">
        <Coins className="w-4 h-4" />
        <span className="text-muted-foreground">Price:</span>
        <span>{formatNumber(networkData.price, 4)}</span>
      </div>
      
      <div className="flex items-center space-x-2">
        <Database className="w-4 h-4" />
        <span className="text-muted-foreground">MCap:</span>
        <span>{formatNumber(networkData.marketCap)}</span>
      </div>
      
      <div className="flex items-center space-x-2">
        <Cpu className="w-4 h-4" />
        <span className="text-muted-foreground">Hashrate:</span>
        <span>{formatHashrate(networkData.hashrate)}</span>
      </div>
    </div>
  );
};

export default NetworkStats;