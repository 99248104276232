import React, { useState, useMemo, useEffect } from 'react';
import { ArrowDown, ArrowUp, ArrowUpDown, Info, Loader2, Star } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Button } from "../components/ui/button";
import { Switch } from "../components/ui/switch"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/ui/tooltip";
import { LineChart, Line, ResponsiveContainer } from 'recharts';

const TOKEN_API_URL = process.env.NEXT_PUBLIC_TOKEN_API_URL || 'https://tokentable-server.vercel.app/api';

export interface TokenData {
  ticker: string;
  maxSupply: number;
  preMint: number;
  mintLimit: number;
  decimal: number;
  totalMinted: number;
  opScoreCreated: number;
  opScoreUpdated: number;
  deployedAt: number;
  status: string;
  revealHash: string;
  holderTotal: number;
  transferTotal: number;
  mintTotal: number;
  price: {
    priceInUsd: number;
    marketCapInUsd: number;
    change24h: number;
  };
  priceHistory: Array<{
    p: number;
    t: number;
  }>;
  tradeVolume: {
    amount: number;
    amountInKas: number;
    amountInUsd: number;
  };
  iconUrl: string;
  data?: {
    results: TokenData[];
  };
}

export interface TokenTableProps {
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
}

export const SparklineCell = React.memo(({ priceCandles, change24h }: {
  priceCandles: Array<{ timestamp: string; close: string; }>;
  change24h: number;
}) => {
  if (!priceCandles?.length) return null;

  const chartData = priceCandles
    .slice(-42)
    .map(candle => ({
      price: parseFloat(candle.close)
    }));

  return (
    <div className="w-24 sm:w-48 h-8">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <Line
            type="monotone"
            dataKey="price"
            stroke={change24h >= 0 ? '#22c55e' : '#ef4444'}
            dot={false}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});

export const RatioCell = React.memo(({ marketCap, volume }: { marketCap: number | undefined, volume: number | undefined }) => {
  if (!marketCap || !volume || marketCap === 0) return <>N/A</>;
  const ratio = (volume / marketCap);
  return <>{ratio.toFixed(2)}</>;
});

export const TokenCell = React.memo(({ token, setCurrentPage, setSearchedToken, isFavorite, onToggleFavorite }: {
  token: TokenData;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
  isFavorite: boolean;
  onToggleFavorite: (ticker: string) => void;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchedToken(token.ticker);
    setCurrentPage('charts');
  };

  const handleStarClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleFavorite(token.ticker);
  };

  return (
    <div className="flex items-center gap-1">
      <Star
        className={`h-4 w-4 cursor-pointer ${isFavorite ? 'fill-yellow-400 text-yellow-400' : 'text-gray-400 hover:text-gray-600 dark:text-gray-600 dark:hover:text-gray-400'}`}
        onClick={handleStarClick}
      />
      <div className="flex items-center gap-1 cursor-pointer hover:text-blue-500" onClick={handleClick}>
        <img src={token.iconUrl || '/token-icon.svg'} alt={token.ticker} className="w-6 h-6 rounded-full bg-gray-100" onError={(e) => { (e.target as HTMLImageElement).src = '/token-icon.svg'; }} />
        {token.ticker}
      </div>
    </div>
  );
});

export const HolderCell = React.memo(({ count, ticker, setCurrentPage, setSearchedToken }: {
  count: number | undefined;
  ticker: string;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchedToken(ticker);
    setCurrentPage('holders');
  };

  return (
    <div className="cursor-pointer hover:text-blue-500 flex justify-center" onClick={handleClick}>
      {count?.toLocaleString() || '0'}
    </div>
  );
});

export const PriceCell = React.memo(({ price }: { price: number | undefined }) => {
  if (!price) return <>$0.00</>;
  if (price < 0.00001) return <>{price.toExponential(4)}</>;
  return <>${price.toFixed(6)}</>;
});

export const MarketCapCell = React.memo(({ marketCap }: { marketCap: number | undefined }) => {
  if (!marketCap) return <>$0.00</>;
  if (marketCap >= 1e9) return <>${(marketCap / 1e9).toFixed(2)}B</>;
  if (marketCap >= 1e6) return <>${(marketCap / 1e6).toFixed(2)}M</>;
  if (marketCap >= 1e3) return <>${(marketCap / 1e3).toFixed(2)}K</>;
  return <>${marketCap.toFixed(2)}</>;
});

export const ChangeCell = React.memo(({ change }: { change: number | undefined }) => {
  const safeChange = change || 0;
  return (
    <span className={`flex items-center justify-center ${safeChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
      {safeChange >= 0 ? <ArrowUp className="h-4 w-4 mr-1" /> : <ArrowDown className="h-4 w-4 mr-1" />}
      {Math.abs(safeChange).toFixed(2)}%
    </span>
  );
});

export const VolumeCell = React.memo(({ volume }: { volume: number | undefined }) => {
  if (!volume) return <>$0.00</>;
  if (volume >= 1e9) return <>${(volume / 1e9).toFixed(2)}B</>;
  if (volume >= 1e6) return <>${(volume / 1e6).toFixed(2)}M</>;
  if (volume >= 1e3) return <>${(volume / 1e3).toFixed(2)}K</>;
  return <>${volume.toFixed(2)}</>;
});

export const AgeCell = React.memo(({ deployedAt }: { deployedAt: number }) => {
  const now = Date.now();
  const diffDays = Math.floor((now - deployedAt) / (1000 * 60 * 60 * 24));
  if (diffDays > 30) return <>{Math.floor(diffDays / 30)}mo ago</>;
  return <>{diffDays}d ago</>;
});

export const TableRowMemo = React.memo(({ token, setCurrentPage, setSearchedToken, isFavorite, onToggleFavorite }: {
  token: any;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
  isFavorite: boolean;
  onToggleFavorite: (ticker: string) => void;
}) => (
  <TableRow className="[&>td]:p-2 [&>td]:sm:p-2">
    <TableCell className="sticky left-0 bg-background z-20 w-[100px] shadow-[2px_0_3px_-1px_rgba(0,0,0,0.1)] dark:shadow-[2px_0_3px_-1px_rgba(255,255,255,0.1)]">
      <TokenCell token={token} setCurrentPage={setCurrentPage} setSearchedToken={setSearchedToken} isFavorite={isFavorite} onToggleFavorite={onToggleFavorite} />
    </TableCell>
    <TableCell className="min-w-[60px] lg:min-w-[100px] whitespace-nowrap text-center px-0">
      <div className="flex items-center justify-center">
        <PriceCell price={token.price?.priceInUsd} />
      </div>
    </TableCell>
    <TableCell className="min-w-[40px] lg:min-w-[100px] whitespace-nowrap text-center px-0">
      <SparklineCell
        priceCandles={token.priceCandles}
        change24h={token.price?.change24h || 0}
      />
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <div className="flex justify-center">
        <MarketCapCell marketCap={token.price?.marketCapInUsd} />
      </div>
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <div className="flex justify-center">
        <VolumeCell volume={token.tradeVolume?.amountInUsd} />
      </div>
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <div className="flex justify-center">
        <RatioCell marketCap={token.price?.marketCapInUsd} volume={token.tradeVolume?.amountInUsd} />
      </div>
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <ChangeCell change={token.price?.change24h} />
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <HolderCell count={token.holderTotal} ticker={token.ticker} setCurrentPage={setCurrentPage} setSearchedToken={setSearchedToken} />
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <div className="flex justify-center">{token.transferTotal?.toLocaleString() || '0'}</div>
    </TableCell>
    <TableCell className="whitespace-nowrap text-center">
      <div className="flex justify-center">
        <AgeCell deployedAt={token.deployedAt} />
      </div>
    </TableCell>
  </TableRow>
));

const TokenTable: React.FC<TokenTableProps> = ({ setCurrentPage, setSearchedToken }) => {
  const [tokens, setTokens] = useState<TokenData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'price.marketCapInUsd',
    direction: 'desc',
  });
  const [currentPage, setLocalCurrentPage] = useState(1);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const tokensPerPage = 50;

  useEffect(() => {
    const savedFavorites = localStorage.getItem('tokenFavorites');
    if (savedFavorites) {
      setFavorites(new Set(JSON.parse(savedFavorites)));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tokenFavorites', JSON.stringify(Array.from(favorites)));
  }, [favorites]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(TOKEN_API_URL);
        if (!response.ok) throw new Error('Failed to fetch tokens');
        const apiResponse = await response.json();
        const flattenedTokens = apiResponse.results.flatMap((item: { data: { results: any; }; }) =>
          item.data?.results || [item]
        );
        setTokens(flattenedTokens);
        setError(null);
      } catch (error) {
        console.error('Error fetching tokens:', error);
        setError(error instanceof Error ? error.message : 'Failed to fetch tokens');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTokens();
    const interval = setInterval(fetchTokens, 300000);
    return () => clearInterval(interval);
  }, []);

  const getValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => {
      if (acc === null || acc === undefined) return 0;
      return acc[part] ?? 0;
    }, obj);
  };

  const compareValues = (a: any, b: any, isAsc: boolean) => {
    if (a === b) return 0;
    if (a === null || a === undefined) return isAsc ? -1 : 1;
    if (b === null || b === undefined) return isAsc ? 1 : -1;
    return isAsc ? (a - b) : (b - a);
  };

  const sortData = (key: string) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key ? (current.direction === 'asc' ? 'desc' : 'asc') : 'desc'
    }));
  };

  const toggleFavorite = (ticker: string) => {
    setFavorites(prev => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(ticker)) {
        newFavorites.delete(ticker);
      } else {
        newFavorites.add(ticker);
      }
      return newFavorites;
    });
  };

  const getMCapVolumeRatio = (token: TokenData) => {
    const mcap = token.price?.marketCapInUsd || 0;
    const volume = token.tradeVolume?.amountInUsd || 0;
    return volume === 0 ? 0 : volume / mcap;
  };

  const sortedData = useMemo(() => {
    if (!tokens.length) return [];
    const startIndex = (currentPage - 1) * tokensPerPage;
    const endIndex = startIndex + tokensPerPage;
    return [...tokens]
      .filter(token => {
        const meetsVolumeRequirement = (token.tradeVolume?.amountInUsd || 0) > 400;
        const meetsMcapRequirement = (token.price?.marketCapInUsd || 0) <= 1000000000; // 1 billion
        return showFavoritesOnly
          ? meetsVolumeRequirement && meetsMcapRequirement && favorites.has(token.ticker)
          : meetsVolumeRequirement && meetsMcapRequirement;
      })
      .sort((a, b) => {
        if (sortConfig.key === 'mcap_volume_ratio') {
          return compareValues(getMCapVolumeRatio(a), getMCapVolumeRatio(b), sortConfig.direction === 'asc');
        }
        const aValue = getValue(a, sortConfig.key);
        const bValue = getValue(b, sortConfig.key);
        return compareValues(aValue, bValue, sortConfig.direction === 'asc');
      })
      .slice(startIndex, endIndex);
  }, [currentPage, sortConfig, tokens, showFavoritesOnly, favorites]);

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return <ArrowUpDown className="ml-2 h-4 w-4" />;
    return sortConfig.direction === 'asc' ? <ArrowUp className="ml-2 h-4 w-4" /> : <ArrowDown className="ml-2 h-4 w-4" />;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin" />
          <p className="text-muted-foreground">Loading token data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center space-y-4">
          <p className="text-red-500">Error loading tokens: {error}</p>
          <Button onClick={() => window.location.reload()}>Retry</Button>
        </div>
      </div>
    );
  }

  if (!tokens.length) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-muted-foreground">No tokens available</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-end px-4">
        <div className="flex items-center gap-2">
          <span className="text-sm">Favorites</span>
          <Switch
            checked={showFavoritesOnly}
            onCheckedChange={setShowFavoritesOnly}
          />
        </div>
      </div>
      <div className="rounded-none sm:rounded-md border">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="[&>th]:p-2 [&>th]:sm:p-4">
                <TableHead className="sticky left-0 bg-background z-30 w-[80px] sm:w-[100px] shadow-[2px_0_3px_-1px_rgba(0,0,0,0.1)] dark:shadow-[2px_0_3px_-1px_rgba(255,255,255,0.1)] text-center">
                  <Button variant="ghost" size="sm" className="flex items-center justify-center w-full -ml-2 text-xs sm:text-base" onClick={() => sortData('ticker')}>
                    Token {renderSortIcon('ticker')}
                  </Button>
                </TableHead>
                <TableHead className="min-w-[50px] sm:min-w-[60px] lg:min-w-[100px] text-center p-0">
                  <Button variant="ghost" size="sm" onClick={() => sortData('price.priceInUsd')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    Price {renderSortIcon('price.priceInUsd')}
                  </Button>
                </TableHead>
                <TableHead className="min-w-[30px] sm:min-w-[40px] lg:min-w-[100px] text-center p-0">
                  <span className="text-xs lg:text-sm text-muted-foreground whitespace-nowrap">7D</span>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('price.marketCapInUsd')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    MCap {renderSortIcon('price.marketCapInUsd')}
                  </Button>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('tradeVolume.amountInUsd')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    Vol {renderSortIcon('tradeVolume.amountInUsd')}
                  </Button>
                </TableHead>
                <TableHead className="text-center w-[100px]">
                  <div className="flex items-center justify-center gap-0.5">
                    <Button variant="ghost" size="sm" onClick={() => sortData('mcap_volume_ratio')} className="flex items-center justify-center whitespace-nowrap text-xs sm:text-base min-w-0 px-2">
                      Vol/Mcap {renderSortIcon('mcap_volume_ratio')}
                    </Button>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Info className="h-4 w-4 text-muted-foreground ml-0.5" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="w-[200px] text-sm">
                            Higher Vol/MCap ratio indicates stronger trading activity relative to market cap. This can suggest higher liquidity and trading interest.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('price.change24h')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    24h {renderSortIcon('price.change24h')}
                  </Button>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('holderTotal')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    Holders {renderSortIcon('holderTotal')}
                  </Button>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('transferTotal')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    Txs {renderSortIcon('transferTotal')}
                  </Button>
                </TableHead>
                <TableHead className="text-center">
                  <Button variant="ghost" size="sm" onClick={() => sortData('deployedAt')} className="flex items-center justify-center whitespace-nowrap w-full text-xs sm:text-base">
                    Age {renderSortIcon('deployedAt')}
                  </Button>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedData.map((token) => (
                <TableRowMemo
                  key={token.ticker}
                  token={token}
                  setCurrentPage={setCurrentPage}
                  setSearchedToken={setSearchedToken}
                  isFavorite={favorites.has(token.ticker)}
                  onToggleFavorite={toggleFavorite}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="flex justify-center gap-2 p-2">
        <Button
          variant="outline"
          onClick={() => setLocalCurrentPage((prev) => Math.max(1, prev - 1))}
          disabled={currentPage === 1}
          className="text-sm"
        >
          Previous
        </Button>
        <Button
          variant="outline"
          onClick={() => setLocalCurrentPage((prev) => prev + 1)}
          disabled={currentPage * tokensPerPage >= tokens.length}
          className="text-sm"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default TokenTable;