import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';
import { useTheme } from './theme-provider';

interface TokenData {
    ticker: string;
    iconUrl: string;
    tradeVolume: {
        amountInUsd: number;
    };
    price: {
        change24h: number;
    };
    data?: {
        results: TokenData[];
    };
}

interface ApiResponse {
    results: TokenData[];
}

const API_URL = process.env.NEXT_PUBLIC_TOKEN_API_URL || 'https://tokentable-server.vercel.app/api';

export function TickerFooter() {
    const { theme } = useTheme();
    const [tokens, setTokens] = React.useState<TokenData[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchTokens = async () => {
            try {
                const response = await fetch(API_URL);
                if (!response.ok) {
                    throw new Error('Failed to fetch token data');
                }
                const apiResponse: ApiResponse = await response.json();
                const flattenedTokens = apiResponse.results.flatMap(item =>
                    item.data?.results || [item]
                );

                setTokens(flattenedTokens);
                setError(null);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to fetch token data');
                console.error('Error fetching token data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTokens();
        const intervalId = setInterval(fetchTokens, 60000);
        return () => clearInterval(intervalId);
    }, []);

    const top50Tokens = React.useMemo(() => {
        return [...tokens]
            .sort((a, b) => (b.tradeVolume?.amountInUsd || 0) - (a.tradeVolume?.amountInUsd || 0))
            .slice(0, 50);
    }, [tokens]);
    if (isLoading) {
        return (
            <div className={`fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur border-t h-8 sm:h-12 flex items-center justify-center z-50 ${theme === 'dark' ? 'border-white/20' : 'border-black/20'}`}>
                <span className="text-muted-foreground text-sm">Loading token data...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className={`fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur border-t h-8 sm:h-12 flex items-center justify-center z-50 ${theme === 'dark' ? 'border-white/20' : 'border-black/20'}`}>
                <span className="text-red-500 text-sm">Error: {error}</span>
            </div>
        );
    }

    return (
        <div className={`fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-t h-8 sm:h-12 flex items-center overflow-hidden z-50 ${theme === 'dark' ? 'border-white/20' : 'border-black/20'}`}>
            <div className="flex whitespace-nowrap animate-ticker-infinite hover:pause-animation">
                {[...top50Tokens, ...top50Tokens].map((token, index) => {
                    const rank = (index % 50) + 1;
                    return (
                        <div
                            key={`${token.ticker}-${index}`}
                            className="flex items-center px-1 sm:px-2 min-w-fit"
                        >
                            <span className="text-xs sm:text-sm text-muted-foreground font-semibold min-w-[1.25rem] sm:min-w-[1.5rem]">
                                #{rank}
                            </span>
                            <img
                                src={token.iconUrl || '/token-icon.svg'}
                                alt={token.ticker}
                                className="w-4 h-4 sm:w-6 sm:h-6 rounded-full"
                                onError={(e) => {
                                    (e.target as HTMLImageElement).src = '/token-icon.svg';
                                }}
                            />
                            <span className="font-medium min-w-[2.5rem] sm:min-w-[3rem] text-xs sm:text-sm">{token.ticker}</span>
                            <span className={`flex items-center min-w-[4rem] sm:min-w-[5rem] text-xs sm:text-sm ${token.price?.change24h >= 0 ? 'text-green-500' : 'text-red-500'
                                }`}>
                                {token.price?.change24h >= 0 ?
                                    <TrendingUp className="h-3 w-3 sm:h-4 sm:w-4 mr-1" /> :
                                    <TrendingDown className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
                                }
                                {Math.abs(token.price?.change24h || 0).toFixed(2)}%
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TickerFooter;