import React, { useState, useEffect } from 'react';
import TVChart from '../components/Chart';
import { TokenInfo } from '../components/TokenInfoSidebar';
import { ChartHeader } from '../components/ChartHeader';
import { Card, CardContent } from "../components/ui/card";
import { Loader2 } from "lucide-react";

export interface TokenData {
  ticker: string;
  iconUrl?: string;
  price: {
    floorPrice: number;
    marketCapInUsd: number;
    change24h: number;
    priceInUsd: number;
  };
  tradeVolume: {
    amountInUsd: number;
  };
  priceHistory: Array<{
    p: number;
    t: number;
  }>;
  holderTotal: number;
  transferTotal: number;
  mintTotal: number;
  socialLinks: Array<{
    type: string;
    url: string;
  }>;
  marketsData: Array<{
    name: string;
    marketData: {
      priceInUsd: number;
      volumeInUsd: number;
    };
    metadata: {
      name: string;
      iconUrl: string;
      url: string;
    };
  }>;
}

const defaultTokenData: TokenData = {
  ticker: '',
  iconUrl: undefined,
  price: {
    floorPrice: 0,
    marketCapInUsd: 0,
    change24h: 0,
    priceInUsd: 0
  },
  tradeVolume: {
    amountInUsd: 0
  },
  priceHistory: [],
  holderTotal: 0,
  transferTotal: 0,
  mintTotal: 0,
  socialLinks: [],
  marketsData: []
}

interface ChartsPageProps {
  tokenData: TokenData;
  searchedToken?: string;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
}

export function ChartsPage({
  tokenData,
  searchedToken,
  setCurrentPage,
  setSearchedToken
}: ChartsPageProps) {
  const [currentTokenData, setCurrentTokenData] = useState<TokenData>(
    tokenData || defaultTokenData
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (searchedToken) {
      fetchTokenData(searchedToken);
    }
  }, [searchedToken]);

  const fetchTokenData = async (ticker: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api-v2-do.kas.fyi/token/krc20/${ticker}/info`
      );
      if (!response.ok) throw new Error('Token not found');
      const data = await response.json();
      const validatedData: TokenData = {
        ...defaultTokenData,
        ...data,
        price: {
          floorPrice: data.price?.floorPrice ?? 0,
          marketCapInUsd: data.price?.marketCapInUsd ?? 0,
          change24h: data.price?.change24h ?? 0,
          priceInUsd: data.price?.priceInUsd ?? 0
        },
        tradeVolume: {
          amountInUsd: data.tradeVolume?.amountInUsd ?? 0
        }
      };
      
      setCurrentTokenData(validatedData);
    } catch (error) {
      console.error('Error fetching token data:', error);
      setError(error instanceof Error ? error.message : 'Failed to fetch token data');
      setCurrentTokenData(defaultTokenData);
    } finally {
      setLoading(false);
    }
  };

  const handleHolderClick = () => {
    if (searchedToken) {
      setSearchedToken(searchedToken);
      setCurrentPage('holders');
    }
  };

  if (!searchedToken) {
    return (
      <div className="h-[calc(100vh-6rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-2">No Token Selected</h2>
          <p className="text-muted-foreground">
            Search for a token or click one from the token list to view its chart
          </p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[calc(100vh-8rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin" />
          <p className="text-muted-foreground">Loading token data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[calc(100vh-8rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-destructive mb-2">Error</h2>
          <p className="text-muted-foreground">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[calc(100vh-8rem)] w-full overflow-y-auto">
      <div className="p-1 space-y-4">
        <ChartHeader
          ticker={currentTokenData.ticker}
          iconUrl={currentTokenData.iconUrl}
          price={currentTokenData.price}
          marketsData={currentTokenData.marketsData}  
          holderTotal={currentTokenData.holderTotal}
          transferTotal={currentTokenData.transferTotal}
          onHolderClick={handleHolderClick}
        />

        <Card className="h-[400px] lg:h-[600px]">
          <CardContent className="p-1 h-full">
            <TVChart
              containerId="tv_chart_container"
              ticker={searchedToken}
            />
          </CardContent>
        </Card>

        <TokenInfo
          ticker={currentTokenData.ticker}
          price={currentTokenData.price}
          socialLinks={currentTokenData.socialLinks}
          marketsData={currentTokenData.marketsData}
          holderTotal={currentTokenData.holderTotal}
          transferTotal={currentTokenData.transferTotal}
          mintTotal={currentTokenData.mintTotal}
          onHolderClick={handleHolderClick}
        />
      </div>
    </div>
  );
}