import React, { useState, useCallback } from 'react';
import { ThemeProvider } from '../theme-provider';
import { ThemeToggle } from '../theme-toggle';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import NetworkStats from '../NetworkStats';
import { Home, BarChart2, Wallet, ShoppingCart, Image, PieChart, Users, Rocket, Book, Search, Menu, Hammer, Loader2, Circle } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { TickerFooter } from '../TickerFooter';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger, } from "../ui/sheet";

interface LayoutProps {
  children: React.ReactNode;
  connected: boolean;
  address?: string;
  handleConnect: () => Promise<void>;
  handleDisconnect: () => Promise<void>;
  currentPage: string;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
  setSearchedAddress: (address: string) => void;
}

interface TokenSuggestion {
  ticker: string;
  iconUrl: string;
}

const navItems = [
  { icon: Home, label: 'Home', id: 'home' },
  { icon: ShoppingCart, label: 'Marketplace', id: 'marketplace' },
  { icon: Users, label: 'Holders', id: 'holders' },
  { icon: BarChart2, label: 'Charts', id: 'charts' },
  { icon: PieChart, label: 'Portfolio', id: 'portfolio' },
  { icon: Wallet, label: 'Wallet Profiler', id: 'profiler' },
  { icon: Book, label: 'DEX Dominance', id: 'dex' },
  { icon: Circle, label: 'Bubble Maps', id: 'bubblemaps' },
  { icon: Hammer, label: 'Tax Tool', id: 'tax' },
  { icon: Image, label: 'NFTs', id: 'nfts' },
];

const TOKEN_API_URL = process.env.NEXT_PUBLIC_TOKEN_API_URL || 'https://tokentable-server.vercel.app/api';

const MobileNav = ({ currentPage, setCurrentPage, onClose }: {
  currentPage: string;
  setCurrentPage: (page: string) => void;
  onClose: () => void;
}) => {
  const handleNavClick = (id: string) => {
    setCurrentPage(id);
    onClose();
  };

  return (
    <nav className="flex flex-col space-y-4 pt-4">
      {navItems.map((item) => (
        <button
          key={item.id}
          onClick={() => handleNavClick(item.id)}
          className={`flex items-center space-x-3 px-4 py-3 rounded-md transition-colors
            ${currentPage === item.id
              ? 'bg-accent text-accent-foreground'
              : 'hover:bg-accent hover:text-accent-foreground'
            }`
          }
        >
          <item.icon className="h-5 w-5" />
          <span>{item.label}</span>
        </button>
      ))}
    </nav>
  );
};

export function Layout({
  children,
  connected,
  handleConnect,
  handleDisconnect,
  currentPage,
  setCurrentPage,
  setSearchedToken,
  setSearchedAddress
}: LayoutProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchError, setSearchError] = useState('');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<TokenSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const searchRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchSuggestions = async (query: string) => {
    if (query.length < 1 || query.length > 6) {
      setSuggestions([]);
      return;
    }

    setIsLoadingSuggestions(true);
    try {
      const response = await fetch(TOKEN_API_URL);
      if (response.ok) {
        const data = await response.json();
        const filteredTokens = data.results
          .flatMap((item: { data: { results: any[]; }; }) => item.data?.results || [item])
          .filter((token: { ticker: string; }) =>
            token.ticker.toLowerCase().includes(query.toLowerCase())
          )
          .slice(0, 5)
          .map((token: { ticker: string; iconUrl: string; }) => ({
            ticker: token.ticker,
            iconUrl: token.iconUrl
          }));
        setSuggestions(filteredTokens);
        setShowSuggestions(true);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setIsLoadingSuggestions(false);
    }
  };

  const validateTokenTicker = async (ticker: string): Promise<boolean> => {
    try {
      const response = await fetch(`https://api-v2-do.kas.fyi/token/krc20/${ticker}/info?includeCharts=true&interval=1m`);
      return response.ok;
    } catch {
      return false;
    }
  };

  const handleSearch = useCallback(async () => {
    if (!searchQuery) return;

    const query = searchQuery.trim().toUpperCase();
    setSearchError('');
    setShowSuggestions(false);

    if (query.length >= 3 && query.length <= 6) {
      const isValidToken = await validateTokenTicker(query);
      if (isValidToken) {
        setCurrentPage('charts');
        setSearchedToken(query);
        setSearchQuery('');
      } else {
        setSearchError('Token not found');
      }
    } else if (query.startsWith('KASPA:')) {
      const isValidAddress = /^kaspa:[a-zA-Z0-9]{10,90}$/.test(query.toLowerCase());
      if (isValidAddress) {
        setCurrentPage('profiler');
        setSearchedAddress(query.toLowerCase());
        setSearchQuery('');
      } else {
        setSearchError('Invalid Kaspa address');
      }
    } else {
      setSearchError('Invalid search query');
    }
  }, [searchQuery, setCurrentPage, setSearchedToken, setSearchedAddress]);

  const handleSuggestionClick = async (ticker: string) => {
    setSearchQuery(ticker);
    setShowSuggestions(false);
    setCurrentPage('charts');
    setSearchedToken(ticker);
  };

  return (
    <ThemeProvider defaultTheme="dark" storageKey="ui-theme">
      <div className="flex min-h-screen flex-col bg-background text-foreground">
        <header className="sticky top-0 z-50 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center justify-between gap-4 px-4">
            <div className="flex items-center gap-4">
              <Sheet open={isNavOpen} onOpenChange={setIsNavOpen}>
                <SheetTrigger asChild>
                  <Button variant="ghost" size="icon" className="lg:hidden">
                    <Menu className="h-5 w-5" />
                  </Button>
                </SheetTrigger>
                <SheetContent side="left" className="w-[280px] sm:w-[340px]">
                  <SheetHeader>
                    <SheetTitle className="flex items-left">
                      <img
                        src="/burt.png"
                        alt="Burt's World Logo"
                        className="h-8 w-8 rounded-full"
                      />
                      Burts World
                    </SheetTitle>
                  </SheetHeader>
                  <MobileNav
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onClose={() => setIsNavOpen(false)}
                  />
                </SheetContent>
              </Sheet>
              <img
                src="/burt.png"
                alt="Burt's World Logo"
                className="h-8 w-8 rounded-full gap-0 sm:h-10 sm:w-10"
              />
            </div>

            <div className="flex flex-1 items-center gap-1 max-w-xl" ref={searchRef}>
              <div className="flex flex-1 flex-col relative">
                <div className="flex items-center">
                  <Input
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchQuery(value);
                      setSearchError('');
                      if (value.length <= 6) {
                        fetchSuggestions(value);
                      } else {
                        setSuggestions([]);
                        setShowSuggestions(false);
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    className={`w-full ${searchError ? 'border-red-500' : ''}`}
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleSearch}
                    className="ml-2"
                  >
                    <Search className="h-4 w-4" />
                  </Button>
                </div>
                {searchError && (
                  <span className="mt-1 text-xs text-red-500">{searchError}</span>
                )}

                {showSuggestions && suggestions.length > 0 && (
                  <div className="absolute top-full left-0 right-0 mt-1 bg-background border rounded-md shadow-lg z-50 max-h-60 overflow-auto">
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.ticker}
                        className="flex items-center gap-2 p-2 hover:bg-accent cursor-pointer"
                        onClick={() => handleSuggestionClick(suggestion.ticker)}
                      >
                        <img
                          src={suggestion.iconUrl || '/token-icon.svg'}
                          alt={suggestion.ticker}
                          className="w-6 h-6 rounded-full"
                          onError={(e) => { (e.target as HTMLImageElement).src = '/token-icon.svg'; }}
                        />
                        <span>{suggestion.ticker}</span>
                      </div>
                    ))}
                  </div>
                )}

                {isLoadingSuggestions && searchQuery.length > 0 && searchQuery.length <= 6 && (
                  <div className="absolute top-full left-0 right-0 mt-1 bg-background border rounded-md p-2 text-center">
                    <Loader2 className="h-4 w-4 animate-spin inline-block" />
                  </div>
                )}
              </div>
            </div>

            <div className="hidden lg:flex justify-center">
              <NetworkStats />
            </div>

            <div className="flex items-center gap-2">
              <ThemeToggle />
              {connected ? (
                <Button
                  variant="destructive"
                  size="sm"
                  onClick={handleDisconnect}
                  className="hidden sm:inline-flex"
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  variant="default"
                  size="sm"
                  onClick={handleConnect}
                  className="hidden sm:inline-flex"
                >
                  Connect
                </Button>
              )}
            </div>
          </div>
        </header>

        <div className="flex flex-1">
          <TooltipProvider>
            <div className="hidden lg:block fixed left-0 top-16 h-[calc(100vh-4rem)] w-16 border-r bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
              <nav className="flex flex-col items-center space-y-4 pt-4">
                {navItems.map((item) => (
                  <Tooltip key={item.id} delayDuration={0}>
                    <TooltipTrigger asChild>
                      <button
                        onClick={() => setCurrentPage(item.id)}
                        className={`flex h-12 w-12 items-center justify-center rounded-md transition-colors
                          ${currentPage === item.id
                            ? 'bg-accent text-accent-foreground shadow-[0_0_15px_rgba(59,130,246,0.5)] ring-2 ring-blue-500/50'
                            : 'hover:bg-accent hover:text-accent-foreground'
                          }`
                        }
                      >
                        <item.icon className="h-5 w-5" />
                      </button>
                    </TooltipTrigger>
                    <TooltipContent side="right" className="flex items-center">
                      {item.label}
                    </TooltipContent>
                  </Tooltip>
                ))}
              </nav>
            </div>
          </TooltipProvider>

          <main className="flex-1 w-full lg:ml-16 pb-8 sm:pb-12">
            {children}
          </main>
        </div>
        <TickerFooter />
      </div>
    </ThemeProvider>
  );
}