import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'recharts';
import { useTheme } from './theme-provider';
import { Switch } from "../components/ui/switch";
import { Label } from "../components/ui/label";

declare global {
  interface Window {
    TradingView: {
      widget: new (config: any) => any;
    };
  }
}

interface TVChartProps {
  containerId: string;
  ticker: string;
  showPriceToggle?: boolean; 
}

interface ChartBar {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

class BurtDataFeed {
  private subscribers: { [key: string]: ((bar: Bar) => void) } = {};
  private lastPrice: number | null = null;
  private currentTicker: string;
  private allData: any[] = [];
  private useKasPrice: boolean;

  constructor(ticker: string = 'BURT', useKasPrice: boolean = false) {
    this.currentTicker = ticker;
    this.useKasPrice = useKasPrice;
  }

  setUseKasPrice(value: boolean) {
    this.useKasPrice = value;
  }

  onReady(callback: (config: any) => void): void {
    setTimeout(() => callback({
      supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
      symbols_types: [{
        name: 'crypto',
        value: 'crypto'
      }]
    }), 0);
  }

  async resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: (symbolInfo: any) => void,
  ): Promise<void> {
    const suffix = this.useKasPrice ? '/KAS' : '/USD';
    const symbolInfo = {
      name: `${this.currentTicker}${suffix}`,
      description: `${this.currentTicker}`,
      type: 'crypto',
      session: '24x7',
      timezone: 'Etc/UTC',
      ticker: `${this.currentTicker}${suffix}`,
      minmov: 1,
      pricescale: 100000000,
      has_intraday: true,
      visible_plots_set: 'ohlcv',
      supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
      data_status: 'streaming',
      has_daily: true,
      has_weekly_and_monthly: true,
      has_empty_bars: true,
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  }

  async getBars(
    symbolInfo: any,
    resolution: string,
    periodParams: any,
    onHistoryCallback: (bars: ChartBar[], meta: { noData: boolean; nextTime?: number }) => void,
  ): Promise<void> {
    try {
      if (this.allData.length === 0) {
        const response = await fetch(`https://chart-api-sigma.vercel.app/api/${this.currentTicker.toLowerCase()}/data`);
        const responseData = await response.json();
        
        if (response.ok && responseData && responseData.candles && Array.isArray(responseData.candles)) {
          this.allData = responseData.candles
            .filter((candle: any) => 
              candle && 
              candle.timestamp && 
              (this.useKasPrice ? 
                candle.open_kas && candle.high_kas && candle.low_kas && candle.close_kas :
                candle.open && candle.high && candle.low && candle.close)
            )
            .sort((a: { timestamp: number; }, b: { timestamp: number; }) => a.timestamp - b.timestamp);
        }
      }

      if (this.allData.length > 0) {
        const bars: ChartBar[] = this.allData
          .map(candle => {
            const open = parseFloat(this.useKasPrice ? candle.open_kas : candle.open);
            const high = parseFloat(this.useKasPrice ? candle.high_kas : candle.high);
            const low = parseFloat(this.useKasPrice ? candle.low_kas : candle.low);
            const close = parseFloat(this.useKasPrice ? candle.close_kas : candle.close);
            const timestamp = Math.floor(candle.timestamp / 1000) * 1000;

            if (isNaN(open) || isNaN(high) || isNaN(low) || isNaN(close) || isNaN(timestamp)) {
              return null;
            }

            return {
              time: timestamp,
              open,
              high,
              low,
              close,
              volume: 0
            };
          })
          .filter((bar): bar is ChartBar => bar !== null);

        onHistoryCallback(bars, {
          noData: bars.length === 0,
        });
        return;
      }
      
      onHistoryCallback([], { noData: true });
    } catch (error) {
      console.error('Error fetching chart data:', error);
      onHistoryCallback([], { noData: true });
    }
  }

  subscribeBars(
    symbolInfo: any,
    resolution: string,
    onRealtimeCallback: (bar: any) => void,
    subscriberUID: string
  ): void {
    this.subscribers[subscriberUID] = onRealtimeCallback;
  }

  unsubscribeBars(subscriberUID: string): void {
    delete this.subscribers[subscriberUID];
  }
}

declare global {
  interface Window {
    TradingView: {
      widget: new (config: any) => any;
    };
  }
}

const TVChart: React.FC<TVChartProps> = ({ 
  containerId, 
  ticker,
  showPriceToggle = true
}) => {
  const chartRef = useRef<any>(null);
  const dataFeedRef = useRef<BurtDataFeed | null>(null);
  const { theme } = useTheme();
  const [useKasPrice, setUseKasPrice] = useState(false);
  const chartTheme = theme === 'dark' ? 'dark' : 'light';
  const handleTogglePrice = (checked: boolean) => {
    setUseKasPrice(checked);
    if (dataFeedRef.current) {
      dataFeedRef.current.setUseKasPrice(checked);
    }
  };

  useEffect(() => {
    const initChart = () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }

      dataFeedRef.current = new BurtDataFeed(ticker, useKasPrice);
      const backgroundColor = chartTheme === 'dark' ? '#09090b' : '#ffffff';
      const widgetOptions: any = {
        debug: false,
        symbol: `${ticker}${useKasPrice ? '/KAS' : '/USD'}`,
        interval: '15',
        container: containerId,
        library_path: '/charting_library/',
        locale: 'en',
        datafeed: dataFeedRef.current,
        fullscreen: false,
        autosize: true,
        theme: chartTheme,
        custom_css_url: '/charting_library/custom.css',
        loading_screen: { backgroundColor },
        timezone: 'Etc/UTC',
        disabled_features: [
          'header_symbol_search',
          'header_timeframes',
          'header_settings',
          'header_compare',
          'header_undo_redo',
          'timeframes_toolbar',
          'go_to_date',
          'create_volume_indicator_by_default',
        ],
        enabled_features: [
          'hide_left_toolbar_by_default',
          'use_localstorage_for_settings',
        ],
        overrides: {
          "mainSeriesProperties.style": 1,
          "mainSeriesProperties.candleStyle.upColor": "#26a69a",
          "mainSeriesProperties.candleStyle.downColor": "#ef5350",
          "mainSeriesProperties.candleStyle.drawWick": true,
          "mainSeriesProperties.candleStyle.drawBorder": true,
          "mainSeriesProperties.candleStyle.borderUpColor": "#26a69a",
          "mainSeriesProperties.candleStyle.borderDownColor": "#ef5350",
          "mainSeriesProperties.candleStyle.wickUpColor": "#26a69a",
          "mainSeriesProperties.candleStyle.wickDownColor": "#ef5350",
          "backgroundColor": backgroundColor,
          "paneProperties.background": backgroundColor,
          "chartProperties.background": backgroundColor,
          "paneProperties.backgroundType": "solid",
          "paneProperties.backgroundGradientStartColor": backgroundColor,
          "paneProperties.backgroundGradientEndColor": backgroundColor,
          "paneProperties.vertGridProperties.color": chartTheme === 'dark' ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
          "paneProperties.horzGridProperties.color": chartTheme === 'dark' ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
          "scalesProperties.textColor": chartTheme === 'dark' ? "#ffffff" : "#000000",
          "scalesProperties.backgroundColor": backgroundColor,
          "legendProperties.showBackground": false,
          "scalesProperties.lineColor": chartTheme === 'dark' ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
      };

      try {
        if (typeof window !== 'undefined' && window.TradingView) {
          chartRef.current = new window.TradingView.widget(widgetOptions);
          
          chartRef.current.onChartReady(() => {
            chartRef.current.changeTheme(chartTheme);
          });
        }
      } catch (error) {
        console.error('Error initializing chart:', error);
      }
    };

    if (typeof window !== 'undefined' && window.TradingView) {
      initChart();
    } else {
      const script = document.createElement('script');
      script.src = '/charting_library/charting_library.js';
      script.onload = () => {
        initChart();
      };
      document.head.appendChild(script);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [containerId, chartTheme, ticker, useKasPrice]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.onChartReady(() => {
        chartRef.current.changeTheme(chartTheme);
      });
    }
  }, [chartTheme]);

  return (
    <div className="w-full h-full flex flex-col">
      {showPriceToggle && (
        <div className="p-2 border-b border-border flex items-center justify-end gap-2">
          <Label htmlFor="price-toggle" className="text-sm">USD</Label>
          <Switch
            id="price-toggle"
            checked={useKasPrice}
            onCheckedChange={handleTogglePrice}
          />
          <Label htmlFor="price-toggle" className="text-sm">KAS</Label>
        </div>
      )}
      <div 
        id={containerId} 
        className="w-full h-full border border-border rounded-lg overflow-hidden"
      />
    </div>
  );
};

export default TVChart;