export interface Advertisement {
  id: number;
  title: string;
  description: string;
  ctaText: string;
  ctaUrl: string;
  gradient: string;
  showVideo?: boolean;
  videoUrl?: string;
  showLogo?: boolean;
  logo?: {
    url: string;
    type: 'svg' | 'webp' | 'png' | 'jpg' | 'jpeg';
    alt?: string;
  };
  showSocials?: boolean;
  socials?: {
    telegram?: string;
    twitter?: string;
  };
}

export const advertisements: Advertisement[] = [
  {
    id: 1,
    title: "BENNIE",
    description: "Infinite Paws-ibilities",
    ctaText: "Visit",
    ctaUrl: "https://benniekas.com",
    gradient: "from-green-500 via-green-600 to-green-800",
    showVideo: true,
    videoUrl: "bennieshirt.png",
    showSocials: true,
    showLogo: true,
    logo: {
      url: "bennie.png",
      type: "png",
      alt: "KRC20 Logo"
    },
    socials: {
      telegram: "https://t.me/+zwcEdHKuOKo2NzUx",
      twitter: "https://x.com/Benniecoin"
    }
  },
  {
    id: 2,
    title: "ZIGGY",
    description: "Minting Now",
    ctaText: "Join",
    ctaUrl: "https://www.ziggykrc.xyz/",
    gradient: "from-red-500 to-blue-500",
    showVideo: false,
    videoUrl: "",
    showSocials: true,
    showLogo: true,
    logo: {
      url: "ziggy.png",
      type: "png",
      alt: "KRC20 Logo"
    },
    socials: {
      telegram: "https://t.me/ziggycoin_kas",
      twitter: "https://x.com/Ziggyonkas"
    }
  },
  {
    id: 3,
    title: "BLOCKS",
    description: "Your future starts here",
    ctaText: "Join",
    ctaUrl: "https://miningblocks.xyz",
    gradient: "from-black to-teal-500",
    showVideo: false,
    videoUrl: "kasy.jpg",
    showSocials: true,
    showLogo: true,
    logo: {
      url: "blocks.png",
      type: "png",
      alt: "KRC20 Logo"
    },
    socials: {
      telegram: "https://t.me/BlocksMining",
      twitter: "https://x.com/Blocksonkas"
    }
  },
  {
    id: 4,
    title: "KROAK",
    description: "Building a #rugfreezone\nmaking KRC-20 safe",
    ctaText: "Join",
    ctaUrl: "https://www.kroakonkaspa.com",
    gradient: "from-yellow-500 via-amber-500 to-orange-500",
    showVideo: false,
    videoUrl: "kasy.jpg",
    showSocials: true,
    showLogo: true,
    logo: {
      url: "kroak.png",
      type: "png",
      alt: "KRC20 Logo"
    },
    socials: {
      telegram: "https://t.me/KroakONkaspa",
      twitter: "https://x.com/KroakONkaspa"
    }
  }
];