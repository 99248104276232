import React, { useState } from 'react';
import { Card } from "./ui/card";
import { TrendingUp, TrendingDown, Wallet, History, ChevronUp, ChevronDown } from 'lucide-react';

interface ChartHeaderProps {
  ticker?: string;
  iconUrl?: string;
  price?: {
    floorPrice?: number;
    marketCapInUsd?: number;
    change24h?: number;
    priceInUsd?: number;  
  } | null;
  marketsData?: Array<{
    name: string;
    marketData: {
      priceInUsd: number;
      volumeInUsd: number;
    };
  }> | null;
  holderTotal?: number;
  transferTotal?: number;
  onHolderClick?: () => void;
}

export const ChartHeader: React.FC<ChartHeaderProps> = ({
  ticker = '',
  iconUrl,
  price = null,
  marketsData = [],
  holderTotal = 0,
  transferTotal = 0,
  onHolderClick = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const change24h = price?.change24h ?? 0;
  const floorPrice = price?.priceInUsd ?? 0;
  const marketCap = price?.marketCapInUsd ?? 0;
  const safeMarketsData = marketsData ?? [];

  const formatPrice = (price: number) => {
    if (price === 0) return '$0.00';
    if (price < 0.00001) return `$${price.toExponential(4)}`;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    }).format(price);
  };

  const formatNumber = (num: number) => {
    if (num === 0) return '$0';
    if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
    if (num >= 1e3) return `$${(num / 1e3).toFixed(2)}K`;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    }).format(num);
  };

  const totalVolume = safeMarketsData.reduce((sum, market) => sum + (market.marketData?.volumeInUsd ?? 0), 0);

  return (
    <Card className="p-2">
      <div className="flex lg:hidden items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <img
            src={iconUrl || '/token-icon.svg'}
            alt={ticker}
            className="w-6 h-6 rounded-full"
            onError={(e) => {
              (e.target as HTMLImageElement).src = '/token-icon.svg';
            }}
          />
          <span className="font-semibold">{ticker}</span>
        </div>
        <button 
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-1 hover:bg-gray-100 rounded-full transition-colors"
        >
          {isExpanded ? (
            <ChevronUp className="h-4 w-4 text-gray-500" />
          ) : (
            <ChevronDown className="h-4 w-4 text-gray-500" />
          )}
        </button>
      </div>

      <div className={`${isExpanded ? 'grid' : 'hidden'} grid-cols-2 gap-2 lg:grid lg:grid-cols-7 lg:gap-1 items-center`}>
        <div className="hidden lg:flex items-center gap-2 col-span-1 justify-start">
          <img
            src={iconUrl || '/token-icon.svg'}
            alt={ticker}
            className="w-6 h-6 rounded-full"
            onError={(e) => {
              (e.target as HTMLImageElement).src = '/token-icon.svg';
            }}
          />
          <span className="font-semibold">{ticker}</span>
        </div>

        <div className="flex flex-col">
          <div className="text-xs text-muted-foreground">Floor Price</div>
          <div className="font-medium text-sm">{formatPrice(floorPrice)}</div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs text-muted-foreground">24h Change</div>
          <div className={`flex items-center text-sm ${change24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            {change24h >= 0 ? (
              <TrendingUp className="h-3 w-3 mr-1" />
            ) : (
              <TrendingDown className="h-3 w-3 mr-1" />
            )}
            {Math.abs(change24h).toFixed(2)}%
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs text-muted-foreground">Market Cap</div>
          <div className="font-medium text-sm">{formatNumber(marketCap)}</div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs text-muted-foreground">24h Volume</div>
          <div className="font-medium text-sm">{formatNumber(totalVolume)}</div>
        </div>

        <div 
          className="flex flex-col cursor-pointer hover:opacity-80 transition-colors"
          onClick={onHolderClick}
          role="button"
          tabIndex={0}
        >
          <div className="text-xs text-muted-foreground flex items-center gap-1">
            <Wallet className="h-3 w-3" /> Holders
          </div>
          <div className="font-medium text-sm">{holderTotal.toLocaleString()}</div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs text-muted-foreground flex items-center gap-1">
            <History className="h-3 w-3" /> Transfers
          </div>
          <div className="font-medium text-sm">{transferTotal.toLocaleString()}</div>
        </div>
      </div>
    </Card>
  );
};

export default ChartHeader;