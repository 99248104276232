import React from 'react';
import { Card, CardContent } from "../components/ui/card";

interface ComingSoonProps {
  feature: string;
}

export function ComingSoon({ feature }: ComingSoonProps) {
  return (
    <div className="p-1">
      <Card>
        <CardContent className="flex flex-col items-center justify-center h-[calc(100vh-6rem)] w-full overflow-hidden">
          <h2 className="text-2xl font-bold mb-4">{feature}</h2>
          <p className="text-muted-foreground">Coming Soon</p>
        </CardContent>
      </Card>
    </div>
  );
}