import React from 'react';
import TokenTable from '../components/TokenTable';
import { AdvertisementBanner } from '../components/AdvertisementBanner';

interface HomePageProps {
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
}

export function HomePage({ setCurrentPage, setSearchedToken }: HomePageProps) {
  return (
    <div className="h-[calc(100vh-6rem)] w-full overflow-auto">
      <div className="space-y-4">
        <AdvertisementBanner />
        <div className="px-0 sm:px-4">
          <TokenTable 
            setCurrentPage={setCurrentPage}
            setSearchedToken={setSearchedToken}
          />
        </div>
      </div>
    </div>
  );
}